import logo from "../../../assets/img/logo-without-bg.png";
import {useRef, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import {Button, Modal} from "react-bootstrap";
import {PatternFormat} from "react-number-format";

function HomeContactUs({realty}) {
    const siteKey = process.env.REACT_APP_SITE_KEY_CAPTCHA;
    const [formData, setFormData] = useState({});
    const [showAddConfrimModalLeads, setAddConfirmModalLeads] = useState(false);
    const [hCaptchaVerified, setHCaptchaVerified] = useState(false);
    const [isModifying, setModifying] = useState(false);

    const formRef = useRef(null);

    const verifyHCaptcha = (token) => {
        // Make a POST request to your backend to verify the hCaptcha token
        axios.post(`${process.env.REACT_APP_API_URL}/verify-hcaptcha`, {hCaptchaToken: token})
            .then((response) => {
                if (response.data.success) {
                    setHCaptchaVerified(true);
                } else {
                    toast.error("CAPTCHA error: Please make sure you're not a robot.")
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };

    const handleCaptchaVerification = (token) => {
        setFormData({...formData, hCaptchaToken: token});
    };

    function confirmAddDataLeads(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            name: formData.get("name"),
            email: formData.get("email"),
            phone: formData.get("phone"),
            message: formData.get("message"),
            property_code: '',
            agent_name: '',
        };
        setFormData(data);
        setAddConfirmModalLeads(true);
    }

    function handleAddDataLeads() {
        if (hCaptchaVerified) {
            axios
                .post(`${process.env.REACT_APP_API_URL}/add/lead`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    toast.success(response.data.message);
                })
                .catch((error) => {
                    console.log(error);
                    toast.error('Message Unsuccessful');
                })
                .finally(() => {
                    setModifying(false);
                    setFormData({});
                    setHCaptchaVerified(false);
                    if (formRef.current) {
                        formRef.current.reset();
                    }
                });
        } else {
            toast.error('Please complete CAPTCHA verification before confirming.');
        }
    }

    return (
        <>
            <section data-aos="fade-up" style={{paddingBottom: '100px', paddingTop: '100px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-xxl-12 offset-xxl-0 text-center"><img
                            className="rounded-circle img-fluid" src={logo} width="577"
                            height="304"/>
                            <h1 style={{color: '#805b43'}}>contact us</h1>
                            <p style={{color: 'rgb(0,0,0)', textAlign: 'left'}}>At Rohe Property Development
                                Corporation,
                                our commitment to excellence extends beyond our design and construction projects. Our
                                dedicated team of real estate professionals is here to assist you in every aspect of
                                your
                                property journey, from land acquisition and project feasibility studies to property
                                management and investment strategies. With a deep understanding of the Davao City real
                                estate market, we provide personalized guidance and tailored solutions to meet your
                                unique
                                needs and aspirations.</p>
                        </div>
                        <div className="col-md-6 col-xl-6 col-xxl-4 offset-xxl-2 text-center align-self-center"
                             style={{textAlign: 'center', fontFamily: 'Arial'}}>
                            <h4 className="text-center" style={{fontFamily: 'Arial', color: '#805b43'}}>Address</h4>
                            <p style={{fontFamily: 'system-ui'}}>{realty.address}</p>
                            <h4 className="text-center" style={{fontFamily: 'Arial', color: '#805b43'}}>Email</h4>
                            <p style={{fontFamily: 'system-ui'}}>{realty.email}</p>
                            <h4 className="text-center" style={{fontFamily: 'Arial', color: '#805b43'}}>pHONE</h4>
                            <p style={{fontFamily: 'system-ui'}}>{realty.phone}</p>
                        </div>
                        <div className="col-md-6 col-xxl-4 text-center">
                            <fieldset>
                                <legend style={{color: '#805b43', fontSize: '38px'}}>Message us</legend>
                                <form style={{textAlign: 'left'}} onSubmit={confirmAddDataLeads} ref={formRef}>
                                    <label className="form-label">Name</label><input
                                    className="form-control" type="text"
                                    style={{textAlign: 'left', fontFamily: 'system-ui'}} name="name"
                                    id="name"
                                    placeholder="Enter your name" required/><label
                                    className="form-label">Email</label><input className="form-control" type="text"
                                                                               style={{
                                                                                   textAlign: 'left',
                                                                                   fontFamily: 'system-ui'
                                                                               }} name="email" required id="email"
                                                                               placeholder="Enter your email"/><label
                                    className="form-label">Phone</label> <PatternFormat className="form-control"
                                                                                        name="phone"
                                                                                        id="phone"
                                                                                        format="(+63)### ### ####"
                                                                                        allowEmptyFormatting mask="_"
                                                                                        required/><label
                                    className="form-label">Message</label><textarea className="form-control"
                                                                                    name="message"
                                                                                    required
                                                                                    id="message"
                                                                                    placeholder="Enter your message"
                                                                                    style={{fontFamily: 'system-ui'}}></textarea>
                                    <div className="col text-center" style={{textAlign: 'justify', marginTop: '5px'}}>
                                        <button className="btn btn-primary"
                                                style={{display: 'inline-block', textAlign: 'center'}}>{isModifying ?
                                            <i className="fa fa-spinner fa-spin"></i> : "SEND"}
                                        </button>
                                    </div>
                                </form>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </section>

            <Modal show={showAddConfrimModalLeads} onHide={() => {
                setAddConfirmModalLeads(false);
                setModifying(false);
            }} centered scrollable
                   backdrop='static'>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Inquiry Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Name:</strong> {formData.name}</p>
                    <p><strong>Email:</strong> {formData.email}</p>
                    <p><strong>Phone:</strong> {formData.phone}</p>
                    <p><strong>Message:</strong> {formData.message}</p>
                    <p><strong>Verify Captcha to Continue</strong></p>
                    <HCaptcha
                        sitekey={siteKey}
                        onVerify={(token) => {
                            verifyHCaptcha(token);
                            handleCaptchaVerification(token);
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => {
                        setAddConfirmModalLeads(false);
                        setModifying(false);
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" disabled={!hCaptchaVerified} onClick={() => {
                        setAddConfirmModalLeads(false);
                        handleAddDataLeads();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default HomeContactUs;