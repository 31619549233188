import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";

function HomeLatestBlogs() {
    const [latestProperties, setLatestProperties] = useState([])
    const [isLoading, setLoading] = useState(true)
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/latest-blogs`)
            .then(response => {
                setLatestProperties(response.data.data)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])


    function handleViewPropertyListing() {
        navigate(`/blogs`);
    }

    function handleViewProperty(id) {
        navigate(`/blog/${id}`);
    }

    return (
        <>
            <section data-aos="fade-up">
                <div className="container py-4 py-xl-5">
                    <div className="row mb-5">
                        <div className="col-md-12 col-lg-12 col-xl-12 offset-lg-1 text-center mx-auto">
                            <h2 style={{color: '#805b43'}}>Latest blogs</h2>
                            <p className="w-lg-50" style={{textAlign: 'justify'}}>Our portfolio showcases a diverse
                                range
                                of projects, each meticulously designed to meet the evolving needs of Davao City
                                residents.
                                From elegant condominiums to thoughtfully planned subdivisions, we strive to create
                                spaces
                                that not only provide comfort and functionality but also foster a sense of belonging and
                                well-being..</p>
                            <div style={{textAlign: 'center'}}>
                                <button className="btn btn-primary" type="button"
                                        onClick={() => handleViewPropertyListing()}>View Blogs
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3">
                        {latestProperties.map((data) => (
                            <div className="col-lg-4" key={data.id}>
                                <div><img className="rounded img-fluid d-block w-100 fit-cover"
                                          style={{height: '200px'}}
                                          src={`${process.env.REACT_APP_API_URL}/uploaded_img/${data.featured_image}`}/>
                                    <div className="py-4">
                                        <h4 style={{color: '#805b43'}}>{data.title}</h4>
                                        <p style={{textAlign: 'justify'}}>{data.summary}</p>
                                        <div style={{textAlign: 'center'}}>
                                            <button className="btn btn-primary"
                                                    onClick={() => handleViewProperty(data.id)}>View
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeLatestBlogs;