import {useState} from "react";
import {GoogleMap, InfoWindow, MarkerF, useLoadScript} from "@react-google-maps/api";
import mapPin from '../../../assets/img/map-pin.png'

const containerStyle = {
    width: '100%', height: '400px',
};
function HomeOffice({realty}) {
    const location = '7.084264234932519, 125.61156198988039'
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
    });
    const [activeMarker, setActiveMarker] = useState(null);

    const closeInfoWindow = () => {
        setActiveMarker(null);
    };

    const handleMarkerClick = (marker) => {
        setActiveMarker(marker);
    };

    const [lat, lng] = location ? location.split(',').map(parseFloat) : [null, null];

    const center = lat && lng ? {lat, lng} : {lat: 7.110548, lng: 125.5127269};

    return (
        <>
            <section data-aos="fade-up" style={{background: '#ffffff00', paddingBottom: '100px', paddingTop: '100px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-xxl-12 offset-xxl-0 text-center">
                            <h1 style={{color: '#805b43'}}>our office</h1>
                            <p style={{color: 'rgb(0,0,0)', textAlign: 'justify'}}>Explore our vibrant headquarters in
                                Davao City, Philippines. Our dedicated team of real estate professionals is committed to
                                turning your dreams into reality. From stylish condominiums to lucrative investments, we
                                prioritize understanding your unique needs. Experience the Rohe difference today and
                                step
                                into a brighter future with us.</p>
                        </div>
                        <div className="col-xxl-12 offset-xxl-0" style={{textAlign: 'center'}}>
                            {!isLoaded ? (<h1>Loading...</h1>) : (
                                <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={13}>
                                    <MarkerF position={{lat: lat, lng: lng}}
                                             onClick={() => handleMarkerClick(realty)}
                                             icon={{
                                                 url: mapPin,
                                             }}>
                                        {activeMarker === realty && (
                                            <InfoWindow position={{lat, lng}} onCloseClick={closeInfoWindow}>
                                                <div>
                                                    <p
                                                        style={{fontFamily: 'Arial'}}>{realty.address}</p>
                                                    <p
                                                        style={{fontFamily: 'Arial'}}>{realty.email}</p>
                                                    <p
                                                        style={{fontFamily: 'Arial'}}>{realty.phone}</p>
                                                    <p style={{fontFamily: 'Arial'}}><a className='view-link'
                                                                                        href={`https://www.google.com/maps?q=${lat},${lng}`}
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer">View
                                                        in
                                                        Google Maps</a></p>
                                                </div>
                                            </InfoWindow>)}
                                    </MarkerF>
                                </GoogleMap>)}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeOffice;