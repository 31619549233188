import {Link, useLocation} from "react-router-dom";
import realtyLogo from '../../assets/img/logo-without-bg.png'
import '../../assets/css/Navbar-Right-Links-icons.css'

function Nav() {
    const location = useLocation();
    const isActiveRoute = (route) => {
        return location.pathname === route;
    };

    return (

        <nav className="navbar navbar-expand-md py-3" style={{background: 'rgb(255,255,255)'}}>
            <div className="container">
                <Link className="navbar-brand d-flex align-items-center" to="/"><img
                    className="img-fluid d-sm-block" src={realtyLogo} width="248px"
                    height="131px"/></Link>
                <button data-bs-toggle="collapse" className="navbar-toggler" data-bs-target="#navcol-3"><span
                    className="visually-hidden">Toggle navigation</span><span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navcol-3">
                    <ul className="navbar-nav mx-auto">
                        <>
                            <li className="nav-item"><Link
                                style={{color: 'rgb(0,0,0)'}}
                                className={`nav-link${isActiveRoute('/') ? ' active' : ''}`} to='/'>Home</Link></li>
                            <li className="nav-item"><Link
                                style={{color: 'rgb(0,0,0)'}}
                                className={`nav-link${isActiveRoute('/projects') ? ' active' : ''}`}
                                to='/projects'>Projects</Link></li>
                            <li className="nav-item"><Link
                                style={{color: 'rgb(0,0,0)'}}
                                className={`nav-link${isActiveRoute('/designs') ? ' active' : ''}`}
                                to='/designs'>Designs</Link></li>
                            <li className="nav-item"><Link
                                style={{color: 'rgb(0,0,0)'}}
                                className={`nav-link${isActiveRoute('/blogs') ? ' active' : ''}`}
                                to='/blogs'>Blogs</Link></li>
                        </>
                    </ul>
                </div>
            </div>
        </nav>

    )
}

export default Nav