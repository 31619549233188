import logo from "../../../../assets/img/logo-without-bg.png";
import axios from "axios";
import {toast} from "react-toastify";
import {useEffect, useRef, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import {PatternFormat} from "react-number-format";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'

function SinglePropertyInquire({realty, data}) {
    const [formData, setFormData] = useState({});
    const [formData2, setFormData2] = useState({});
    const [showAddConfrimModal, setAddConfirmModal] = useState(false);
    const [showAddConfrimModalLeads, setAddConfirmModalLeads] = useState(false);
    const [isModifying, setModifying] = useState(false);

    const [availableDates2, setAvailableDates2] = useState([]);
    const [timeIntervals, setTimeIntervals] = useState([]);
    const [selectedTime, setSelectedTime] = useState('');
    const moment = require('moment');
    const [selectedDate, setSelectedDate] = useState(null);

    const siteKey = process.env.REACT_APP_SITE_KEY_CAPTCHA;

    const formRefTripping = useRef(null);
    const formRefLeads = useRef(null);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/sitetrippingdatetime_view`, {})
            .then(response => {
                const data = response.data.data
                setAvailableDates2(data);
            })
            .catch(error => {
                console.log(error);
            })
    }, []);

    useEffect(() => {
        if (selectedDate) {
            // Make a request to your backend to fetch reservations for the selected date
            axios.get(`${process.env.REACT_APP_API_URL}/reservations`, {params: {date: selectedDate}})
                .then(response => {
                    const reservationsData = response.data.data;
                    const availableTimes = timeIntervals.filter(interval =>
                        !reservationsData.some(reservation => reservation.trim() === interval.trim())
                    );
                    // Set the available times in the state
                    setTimeIntervals(availableTimes);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [selectedDate]);

    const isDateEnabled = date => {
        const dayOfWeek = date.toLocaleDateString('en-US', {weekday: 'long'}).toLowerCase();
        const dayData = availableDates2.find(item => item.day_of_week.toLowerCase() === dayOfWeek);

        if (!dayData) {
            return false;
        }
        return true;
    };

    const convertTo24HourFormat = (time) => {
        return moment(time, 'hh:mm A').format('HH:mm'); // Convert 12-hour format to 24-hour format
    };

    const convertTo12HourFormat = (hour, minute) => {
        if (hour === 0) {
            return `12:${minute < 10 ? '0' : ''}${minute} AM`;
        } else if (hour < 12) {
            return `${hour}:${minute < 10 ? '0' : ''}${minute} AM`;
        } else if (hour === 12) {
            return `12:${minute < 10 ? '0' : ''}${minute} PM`;
        } else {
            return `${hour - 12}:${minute < 10 ? '0' : ''}${minute} PM`;
        }
    };

    const updateTimeIntervals = (dayData) => {
        const startTime = convertTo24HourFormat(dayData.start_time);
        const endTime = convertTo24HourFormat(dayData.end_time);
        const [startHour, startMinute] = startTime.split(':').map(Number);
        const [endHour, endMinute] = endTime.split(':').map(Number);

        const intervals = [];
        let currentHour = startHour;
        let currentMinute = startMinute;

        while (currentHour < endHour || (currentHour === endHour && currentMinute <= endMinute)) {
            const timeString = convertTo12HourFormat(currentHour, currentMinute);
            intervals.push(timeString);

            currentHour += 1; // Add 1 hour for each interval
        }

        setTimeIntervals(intervals);

    };

    const handleDateChange = selectedDate => {
        setSelectedDate(selectedDate);

        const dayOfWeek = selectedDate.toLocaleDateString('en-US', {weekday: 'long'}).toLowerCase();
        const dayData = availableDates2.find(item => item.day_of_week.toLowerCase() === dayOfWeek);

        if (dayData) {
            updateTimeIntervals(dayData);
        }

        setSelectedTime('');
    };

    const handleTimeChange = e => {
        setSelectedTime(e.target.value);
    };

    const [hCaptchaVerified, setHCaptchaVerified] = useState(false);
    const [hCaptchaVerified2, setHCaptchaVerified2] = useState(false);
    const verifyHCaptcha = (token) => {
        // Make a POST request to your backend to verify the hCaptcha token
        axios.post(`${process.env.REACT_APP_API_URL}/verify-hcaptcha`, {hCaptchaToken: token})
            .then((response) => {
                if (response.data.success) {
                    setHCaptchaVerified(true);
                } else {
                    toast.error("CAPTCHA error: Please make sure you're not a robot.")
                }
            })
            .catch((error) => {
                // Handle the error, e.g., show an error message
            });
    };

    const verifyHCaptcha2 = (token) => {
        // Make a POST request to your backend to verify the hCaptcha token
        axios.post(`${process.env.REACT_APP_API_URL}/verify-hcaptcha`, {hCaptchaToken: token})
            .then((response) => {
                if (response.data.success) {
                    setHCaptchaVerified2(true);
                } else {
                    toast.error("CAPTCHA error: Please make sure you're not a robot.")
                }
            })
            .catch((error) => {
                // Handle the error, e.g., show an error message
            });
    };

    const handleCaptchaVerification = (token) => {
        setFormData({...formData, hCaptchaToken: token});
    };

    const handleCaptchaVerification2 = (token) => {
        setFormData2({...formData2, hCaptchaToken: token});
    };

    function confirmAddData(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            name: formData.get("name"),
            email: formData.get("email"),
            phone: formData.get("phone"),
            date: formData.get("date"),
            time: formData.get("time"),
            property_code: formData.get("property_code"),
            agent_name: '',
        };
        setFormData(data);
        setAddConfirmModal(true);
    }

    function handleAddData() {
        if (hCaptchaVerified) {
            axios
                .post(`${process.env.REACT_APP_API_URL}/add/sitetrippingschedule`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    if (response.status === 400) {
                        toast.error(response.data.message)
                    } else {
                        toast.success(response.data.message)
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast.error('Schedule Unsuccessful');
                })
                .finally(() => {
                    setModifying(false);
                    setSelectedTime('');
                    setFormData({});
                    setHCaptchaVerified(false);
                    setSelectedDate(null)

                    if (formRefTripping.current) {
                        formRefTripping.current.reset();
                    }
                });
        } else {
            toast.error('Please complete CAPTCHA verification before confirming.');
        }
    }

    function confirmAddDataLeads(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            name: formData.get("name"),
            email: formData.get("email"),
            phone: formData.get("phone"),
            message: formData.get("message"),
            property_code: formData.get("property_code"),
            agent_name: '',
        };
        setFormData2(data);
        setAddConfirmModalLeads(true);
    }

    function handleAddDataLeads() {
        if (hCaptchaVerified2) {
            axios
                .post(`${process.env.REACT_APP_API_URL}/add/lead`, formData2, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    toast.success(response.data.message);
                })
                .catch((error) => {
                    console.log(error);
                    toast.error('Message Unsuccessful');
                })
                .finally(() => {
                    setModifying(false);
                    setFormData2({});
                    setHCaptchaVerified2(false);
                    if (formRefLeads.current) {
                        formRefLeads.current.reset();
                    }
                });
        } else {
            toast.error('Please complete CAPTCHA verification before confirming.');
        }
    }

    return (
        <>
            <section data-aos="fade-up">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-xxl-12 offset-xxl-0 text-center"><img
                            className="rounded-circle img-fluid" src={logo} width="515"
                            height="272"/>
                            <h2 style={{color: '#805b43'}}>INquire now</h2>
                            <p style={{color: 'rgb(0,0,0)', textAlign: 'left'}}>At Rohe Property Development
                                Corporation,
                                our commitment to excellence extends beyond our design and construction projects. Our
                                dedicated team of real estate professionals is here to assist you in every aspect of
                                your
                                property journey, from land acquisition and project feasibility studies to property
                                management and investment strategies. With a deep understanding of the Davao City real
                                estate market, we provide personalized guidance and tailored solutions to meet your
                                unique
                                needs and aspirations.</p>
                        </div>
                        <div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 offset-xxl-0 text-center align-self-center"
                             style={{textAlign: 'center', fontFamily: 'Arial'}}>
                            <h4 className="text-center" style={{fontFamily: 'Arial', color: '#805b43'}}>Address</h4>
                            <p style={{fontFamily: 'system-ui'}}>{realty.address}</p>
                            <h4 className="text-center" style={{fontFamily: 'Arial', color: '#805b43'}}>Email</h4>
                            <p style={{fontFamily: 'system-ui'}}>{realty.email}</p>
                            <h4 className="text-center" style={{fontFamily: 'Arial', color: '#805b43'}}>pHONE</h4>
                            <p style={{fontFamily: 'system-ui'}}>{realty.phone}</p>
                        </div>
                        <div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-center">
                            <fieldset>
                                <legend style={{color: '#805b43', fontSize: '25px'}}>Message us</legend>
                                <form style={{textAlign: 'left'}} onSubmit={confirmAddDataLeads} ref={formRefLeads}>
                                    <label
                                        className="form-label">Name</label>
                                    <input
                                        className="form-control" type="text"
                                        style={{textAlign: 'left', fontFamily: 'system-ui'}} name="name" id='name'
                                        placeholder="Enter your name" required/>
                                    <label
                                        className="form-label">Email</label>
                                    <input className="form-control" type="text" style={{
                                        textAlign: 'left', fontFamily: 'system-ui'
                                    }} name="email" id="email" required placeholder="Enter your email"/>
                                    <label
                                        className="form-label">Phone</label>
                                    <PatternFormat className="form-control" name="phone"
                                                   id="phone"
                                                   format="(+63)### ### ####"
                                                   allowEmptyFormatting mask="_"
                                                   required/>
                                    <label
                                        className="form-label">Message</label>
                                    <textarea className="form-control" name="message"
                                              id='message'
                                              required
                                              placeholder="Enter your message"
                                              style={{fontFamily: 'system-ui'}}></textarea>
                                    <input className="form-control" type="text" id="property_code"
                                           name="property_code" value={data.name} hidden readOnly
                                           required/>
                                    <div className="col text-center"
                                         style={{textAlign: 'justify', marginTop: '5px', marginBottom: '5px'}}>
                                        <button className="btn btn-primary"
                                                style={{display: 'inline-block', textAlign: 'center'}}>{isModifying ?
                                            <i className="fa fa-spinner fa-spin"></i> : "SEND"}
                                        </button>
                                    </div>
                                </form>
                            </fieldset>
                        </div>
                        <div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-center">
                            <fieldset>
                                <legend style={{color: '#805b43', fontSize: '25px'}}>Schedule Office Visit</legend>
                                <form style={{textAlign: 'left'}} onSubmit={confirmAddData} ref={formRefTripping}>
                                    <input type="text" className="form-control" id="property_code" name='property_code'
                                           value={data.name} required hidden/>
                                    <label className="form-label">Name</label>
                                    <input
                                        className="form-control" type="text"
                                        style={{textAlign: 'left', fontFamily: 'system-ui'}} name="name"
                                        placeholder="Enter your name" required/>
                                    <label
                                        className="form-label">Email</label>
                                    <input className="form-control" type="text" style={{
                                        textAlign: 'left',
                                        fontFamily: 'system-ui'
                                    }} name="email" required placeholder="Enter your email"/>
                                    <label
                                        className="form-label">Phone</label>
                                    <PatternFormat className="form-control" name="phone" id="phone"
                                                   format="(+63)### ### ####"
                                                   allowEmptyFormatting mask="_"
                                                   required/>
                                    <label className="form-label">Date</label>
                                    <DatePicker
                                        className="form-control"
                                        name="date"
                                        id="date"
                                        showIcon
                                        fixedHeight
                                        selected={selectedDate}
                                        onChange={handleDateChange}
                                        minDate={new Date()} // Minimum date is the current date
                                        filterDate={isDateEnabled} // Enable or disable dates based on your logic
                                    />
                                    <label
                                        className="form-label d-block">Time</label>
                                    <select
                                        name="time"
                                        id="time"
                                        className="form-control mb-1"
                                        value={selectedTime}
                                        onChange={handleTimeChange}
                                        required
                                        style={{fontFamily: 'system-ui'}}
                                    >
                                        <option value="">Select Available Time</option>
                                        {timeIntervals.map(interval => (
                                            <option key={interval} value={interval}>
                                                {interval}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="col text-center" style={{textAlign: 'justify', marginTop: '5px'}}>
                                        <button className="btn btn-primary"
                                                style={{display: 'inline-block', textAlign: 'center'}}>{isModifying ?
                                            <i className="fa fa-spinner fa-spin"></i> : "SUBMIT"}
                                        </button>
                                    </div>
                                </form>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </section>

            <Modal show={showAddConfrimModal} onHide={() => setAddConfirmModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm Schedule Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Name:</strong> {formData.name}</p>
                    <p><strong>Email:</strong> {formData.email}</p>
                    <p><strong>Phone:</strong> {formData.phone}</p>
                    <p><strong>Date:</strong> {formData.date}</p>
                    <p><strong>Time:</strong> {formData.time}</p>
                    <p><strong>Verify Captcha to Continue</strong></p>
                    <HCaptcha
                        sitekey={siteKey}
                        onVerify={(token) => {
                            verifyHCaptcha(token);
                            handleCaptchaVerification(token);
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setAddConfirmModal(false);
                        setModifying(false);
                    }
                    }>
                        Cancel
                    </Button>
                    <Button variant="primary" disabled={!hCaptchaVerified} onClick={() => {
                        setAddConfirmModal(false);
                        handleAddData();

                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAddConfrimModalLeads} onHide={() => setAddConfirmModalLeads(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm Inquiry Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Name:</strong> {formData2.name}</p>
                    <p><strong>Email:</strong> {formData2.email}</p>
                    <p><strong>Phone:</strong> {formData2.phone}</p>
                    <p><strong>Message:</strong> {formData2.message}</p>
                    <p><strong>Verify Captcha to Continue</strong></p>
                    <HCaptcha
                        sitekey={siteKey}
                        onVerify={(token) => {
                            verifyHCaptcha2(token);
                            handleCaptchaVerification2(token);
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setAddConfirmModalLeads(false);
                        setModifying(false);
                    }
                    }>
                        Cancel
                    </Button>
                    <Button variant="primary" disabled={!hCaptchaVerified2} onClick={() => {
                        setAddConfirmModalLeads(false);
                        handleAddDataLeads();

                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SinglePropertyInquire;