import HomeHero from "./HomeSections/HomeHero";
import HomeAbout from "./HomeSections/HomeAbout";
import HomeLatestProjects from "./HomeSections/HomeLatestProjects";
import HomeLatestDesigns from "./HomeSections/HomeLatestDesigns";
import HomeLatestBlogs from "./HomeSections/HomeLatestBlogs";
import HomeContactUs from "./HomeSections/HomeContactUs";
import HomeOffice from "./HomeSections/HomeOffice";
import {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import Loading from "../others/Loading";
import {Helmet} from "react-helmet";
import logo from "../../assets/img/logo-without-bg.png"

function Home() {
    const navigate = useNavigate();
    const [realty, setRealty] = useState([])
    const [isLoading, setLoading] = useState(true)


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/realty`)
            .then(response => {
                setRealty(response.data)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    if (isLoading) {
        return (
            <Loading/>
        );
    }
    return (
        <>
            <Helmet>
                <title>Rohe Property Development Corporation</title>
                <meta property="og:title" content="Rohe Property Development Corporation"/>
                <meta property="og:description" content="Rohe Property Development Corporation"/>
                <meta property="og:url" content="https://roheproperties.com/"/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content={logo}/>
            </Helmet>
            <HomeHero/>
            <HomeAbout/>
            <HomeLatestProjects/>
            <hr/>
            <HomeLatestDesigns/>
            <hr/>
            <HomeLatestBlogs/>
            <hr/>
            <HomeContactUs realty={realty}/>
            <HomeOffice realty={realty}/>
        </>
    )
}

export default Home;